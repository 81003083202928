<template>
    <div class="home">
        <div class="container mt-4 text-center" v-if="loading">
            <div class="row">
                <div class="col-12">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden"></span>
                    </div>  
                </div>
            </div>
        </div>

        <div class="container" v-if="!loading">
            <div class="row mt-4">
                <div class="col-12">
                    <p class="display-6">Statistiken</p>
                </div>
            </div>
            <div class="row mt-4" v-if="orders == null">
                <div class="col-12">
                    <div class="alert alert-danger" role="alert">
                        Daten konnten nicht heruntergeladen werden
                    </div>
                </div>
            </div>
            <div class="row" v-if="orders != null">
                <div class="col-12 col-md-4">
                    <div class="card text-bg-info mb-3">
                        <div class="card-header">Anzahl aller Bestellungen</div>
                        <div class="card-body">
                            <h5 class="card-title">{{ orders.length }}</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="card text-bg-info mb-3">
                        <div class="card-header">Abgeschlossene Bestellungen</div>
                        <div class="card-body">
                            <h5 class="card-title">{{ getOrdersByStatus('C').length }}</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="card text-bg-info mb-3">
                        <div class="card-header">Offene Bestellungen</div>
                        <div class="card-body">
                            <h5 class="card-title">{{ getOrdersByStatus('O').length }}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4" v-if="orders != null">
                <div class="col-12 col-md-4">
                    <div class="card text-bg-primary mb-3">
                        <div class="card-header">Bruttosumme</div>
                        <div class="card-body">
                            <h5 class="card-title">{{ getGrossAmount().toFixed(2) }} €</h5>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="card text-bg-primary mb-3">
                        <div class="card-header">Durchschnitt im Monat (Brutto)</div>
                        <div class="card-body">
                            <h5 class="card-title">{{ getAverageMonthlyGross().toFixed(2) }} €</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DartsBasarService from '@/services/DartsBasarService.js'

export default {
    name: 'StatsView',
    data: () => {
        return {
            orders: null,
            loading: true,
        }
    },
    mounted: async function() {
        const token = this.$route.query.token
        this.orders = await this.getOrders(token)
        this.loading = false

        console.log(this.orders);
    },
    methods: {
        getOrders: async function(token) {
            return await DartsBasarService.getOrders(token)
        },
        changeRoute: function(to) {
            if (this.$router.currentRoute.fullPath != to) {
                this.$router.push(to)
            }
        },
        getOrdersByStatus: function(status) {
            const list = [];
            for (const order of this.orders) {
                if (order.status == status) {
                    list.push(order)
                }
            }
            return list;
        },
        getGrossAmount: function() {
            let total = 0.0;
            for (const order of this.orders) {
                total = total + parseFloat(order.total)
            }
            return total;
        },
        getAverageMonthlyGross: function() {
            const firstOrder = this.orders[this.orders.length-1];
            const firstOrderTimestamp = firstOrder.timestamp;

            const orderDate = new Date(firstOrderTimestamp * 1000);
            const currentDate = new Date()

            const monthsSinceFirstOrder = this.monthDiff(orderDate, currentDate)

            return this.getGrossAmount() / monthsSinceFirstOrder;
        },
        monthDiff: function(d1, d2) {
            var months;
            months = (d2.getFullYear() - d1.getFullYear()) * 12;
            months -= d1.getMonth();
            months += d2.getMonth();
            return months <= 0 ? 0 : months;
        }
    },
}
</script>
