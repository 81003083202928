<template>
    <div class="home">
        <div class="container mt-4 text-center" v-if="loading">
            <div class="row">
                <div class="col-12">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden"></span>
                    </div>  
                </div>
            </div>
        </div>

        <div class="container" v-if="!loading && data != null">
            <div class="row mt-4">
                <div class="col-12">
                    <p class="display-6">Shop-Systeme</p>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-12 mt-4" v-for="(system, index) in data" :key="index">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">{{ system.name }}</h5>
                            <p class="card-text">{{ system.description??'' }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DartsBasarService from '@/services/DartsBasarService.js'

export default {
    name: 'ShopSystemsView',
    data: () => {
        return {
            loading: true,
            data: null,
        }
    },
    mounted: async function() {
        const token = this.$route.query.token
        this.data = await DartsBasarService.getShopSystems()
        console.log(this.data)
        this.loading = false
    },
}
</script>
