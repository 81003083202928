<template>
    <div class="home">
        <div class="container mt-4 text-center" v-if="loading">
            <div class="row">
                <div class="col-12">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden"></span>
                    </div>  
                </div>
            </div>
        </div>

        <div class="container" v-if="!loading && data != null">
            <div class="row mt-4">
                <div class="col-12">
                    <p class="display-6">{{ data.name }}</p>
                </div>
                <div class="col-12">
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Logs
                            </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <p v-if="data.logs.length == 0">
                                        Keine Logs vorhanden
                                    </p>
                                    <p v-for="(log, index) in data.logs" :key="index">
                                        {{ log }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Error Logs
                            </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <p v-if="data.errorLogs.length == 0">
                                        Keine Logs vorhanden
                                    </p>
                                    <p v-for="(log, index) in data.errorLogs" :key="index">
                                        {{ log }}
                                    </p>
                            </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Produkte ({{ data.products.length }})
                            </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="row">
                                        <div class="col-12 col-md-6 mt-5" v-for="(product, index) in data.products" :key="index">
                                            <div class="card">
                                                <!-- <img :src="product.imagePairs[0].imagePath" class="card-img-top" v-if="product.imagePairs.length > 0"> -->
                                                <div class="card-body">
                                                    <h5 class="card-title">{{ product.name }}</h5>
                                                    <p class="card-text">Artikelnummer: {{ product.productCode }}</p>
                                                    <p class="card-text">Bestand: {{ product.amount }}</p>
                                                    <a :href="`https://dartsbasar.de/dartsadmin180.php?dispatch=products.update&product_id=${product.productId}`" target="_blank" class="btn btn-primary">Bearbeiten</a>
                                                    <a class="btn btn-primary" style="margin-left: 5px;" data-bs-toggle="collapse" :href="`#collapseExample-${index}`" role="button" aria-expanded="false" :aria-controls="`collapseExample-${index}`" v-if="product.imagePairs.length > 0">
                                                        Produktbild
                                                    </a>
                                                    <div class="collapse" :id="`collapseExample-${index}`">
                                                        <div class="card card-body mt-4">
                                                           <img :src="product.imagePairs[0].imagePath" class="card-img-top" v-if="product.imagePairs.length > 0" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                Produkte ohne Artikelnummer ({{ productsWithoutCode.length }})
                            </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="row">
                                        <div class="col-12 col-md-6 mt-5" v-for="(product, index) in productsWithoutCode" :key="index">
                                            <div class="card">
                                                <!-- <img :src="product.imagePairs[0].imagePath" class="card-img-top" v-if="product.imagePairs.length > 0"> -->
                                                <div class="card-body">
                                                    <h5 class="card-title">{{ product.name }}</h5>
                                                    <p class="card-text">Artikelnummer: {{ product.productCode }}</p>
                                                    <p class="card-text">Bestand: {{ product.amount }}</p>
                                                    <a :href="`https://dartsbasar.de/dartsadmin180.php?dispatch=products.update&product_id=${product.productId}`" target="_blank" class="btn btn-primary">Bearbeiten</a>
                                                    <a class="btn btn-primary" style="margin-left: 5px;" data-bs-toggle="collapse" :href="`#collapseExample-${index}`" role="button" aria-expanded="false" :aria-controls="`collapseExample-${index}`" v-if="product.imagePairs.length > 0">
                                                        Produktbild
                                                    </a>
                                                    <div class="collapse" :id="`collapseExample-${index}`">
                                                        <div class="card card-body mt-4">
                                                            <img :src="product.imagePairs[0].imagePath" class="card-img-top" v-if="product.imagePairs.length > 0" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                Produkte ohne Bestand ({{ productsWithoutAmount.length }})
                            </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <div class="row">
                                        <div class="col-12 col-md-6 mt-5" v-for="(product, index) in productsWithoutAmount" :key="index">
                                            <div class="card">
                                                <!-- <img :src="product.imagePairs[0].imagePath" class="card-img-top" v-if="product.imagePairs.length > 0"> -->
                                                <div class="card-body">
                                                    <h5 class="card-title">{{ product.name }}</h5>
                                                    <p class="card-text">Artikelnummer: {{ product.productCode }}</p>
                                                    <p class="card-text">Bestand: {{ product.amount }}</p>
                                                    <a :href="`https://dartsbasar.de/dartsadmin180.php?dispatch=products.update&product_id=${product.productId}`" target="_blank" class="btn btn-primary">Bearbeiten</a>
                                                    <a class="btn btn-primary" style="margin-left: 5px;" data-bs-toggle="collapse" :href="`#collapseExample-${index}`" role="button" aria-expanded="false" :aria-controls="`collapseExample-${index}`" v-if="product.imagePairs.length > 0">
                                                        Produktbild
                                                    </a>
                                                    <div class="collapse" :id="`collapseExample-${index}`">
                                                        <div class="card card-body mt-4">
                                                            <img :src="product.imagePairs[0].imagePath" class="card-img-top" v-if="product.imagePairs.length > 0" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DartsBasarService from '@/services/DartsBasarService.js'

export default {
    name: 'ShopSystemView',
    data: () => {
        return {
            loading: true,
            data: null,
            productsWithoutCode: [],
            productsWithoutAmount: [],
        }
    },
    mounted: async function() {
        const id = this.$route.params.id
        this.data = await DartsBasarService.getShopSystem(id)
        this.loading = false

        for (const product of this.data.products) {
            if (product.productCode == null || product.productCode == '') {
                this.productsWithoutCode.push(product)
            }

            if (product.amount == null || product.amount == 0) {
                this.productsWithoutAmount.push(product)
            }
        }
    },
    methods: {

    },
}
</script>
