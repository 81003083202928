<template>
    <div class="home">
        <div class="container mt-4 text-center" v-if="loading">
            <div class="row">
                <div class="col-12">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden"></span>
                    </div>  
                </div>
            </div>
        </div>

        <div class="container" v-if="!loading">
            <div class="row mt-4">
                <div class="col-12">
                    <p class="display-6">Lagerbestand Cron-Jobs</p>
                    <p>Intervall: Alle <b>30</b> Minuten</p>
                </div>
            </div>
            <div class="row mt-4" v-if="homeData == null">
                <div class="col-12">
                    <div class="alert alert-danger" role="alert">
                        Daten konnten nicht heruntergeladen werden
                    </div>
                </div>
            </div>
            <div class="row mt-4" v-if="homeData != null">
                <div class="col-12 col-md-4 mt-4" v-for="(job, index) in homeData.jobs" :key="index">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">{{ job.name }}</h5>
                            <p class="card-text">Status: <span :style="homeData.activeJobs.includes(job.id) ? 'color: green;' : 'color: red;'">{{ homeData.activeJobs.includes(job.id) ? 'Aktiv' : 'Inaktiv' }}</span></p>
                            <p class="card-text">Typ: {{ job.type }}</p>
                            <a href="#" class="btn btn-primary" @click="changeRoute(`/system/${job.id}`)">Details</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DartsBasarService from '@/services/DartsBasarService.js'

export default {
    name: 'HomeView',
    data: () => {
        return {
            homeData: null,
            loading: true,
        }
    },
    mounted: async function() {
        const token = this.$route.query.token
        this.homeData = await this.getHomeData(token)
        this.loading = false
    },
    methods: {
        getHomeData: async function(token) {
            return await DartsBasarService.getHome(token)
        },
        changeRoute: function(to) {
            if (this.$router.currentRoute.fullPath != to) {
                this.$router.push(to)
            }
        }
    },
}
</script>
