import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ChargesView from '../views/ChargesView.vue'
import StatsView from '../views/StatsView.vue'
import ShopSystemView from '../views/ShopSystemView.vue'
import ShopSystemsView from '../views/ShopSystemsView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/charges',
    name: 'charges',
    component: ChargesView,
  },
  {
    path: '/stats',
    name: 'stats',
    component: StatsView,
  },
  {
    path: '/system/:id',
    name: 'shopsystem',
    component: ShopSystemView
  },
  {
    path: '/systems',
    name: 'shopsystems',
    component: ShopSystemsView
  },
]

const router = new VueRouter({
  routes
})

export default router
