import axios from 'axios'

const get = async (url, headers) => {
    try {
        const result = await axios.get(url)
        return result.data
    } catch (error) {
        console.log('[APIFactory - GET - Error]', error)
        return null
    }
}

const post = async () => {

}

export default {
    get,
    post,
}