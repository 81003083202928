<template>
    <div id="app">
        <NavBar/>
        <router-view/>
    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'

export default {
    name: 'App',
    components: {
        NavBar,
    },
    mounted: function() {
        const token = this.$route.query.token
        if (token !== undefined && token != null) {
            localStorage.token = token
        }
    }
}
</script>