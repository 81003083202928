<template>
    <div class="home">
        <div class="container mt-4 text-center" v-if="loading">
            <div class="row">
                <div class="col-12">
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden"></span>
                    </div>  
                </div>
            </div>
        </div>

        <div class="container" v-if="!loading">
            <div class="row mt-4">
                <div class="col-12">
                    <p class="display-6">Kostenübersicht</p>
                </div>
            </div>
            <div class="row mt-4" v-if="charges == null">
                <div class="col-12">
                    <div class="alert alert-danger" role="alert">
                        Daten konnten nicht heruntergeladen werden
                    </div>
                </div>
            </div>
            <div class="row" v-if="charges != null">
                <div class="col-12 col-md-4 mt-4">
                    <div class="card text-bg-primary mb-3" style="max-width: 18rem;">
                        <div class="card-header">Monatliche Fixkosten</div>
                        <div class="card-body">
                            <h5 class="card-title">{{ getTotalCharges().toFixed(2) }} €</h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4" v-if="charges != null">
                <div class="col-12 col-md-4 mt-4" v-for="(charge, index) in charges" :key="index">
                    <div class="card">
                        <div class="card-header">
                            {{ charge.name }}
                        </div>
                        <div class="card-body">
                            {{ charge.description }}
                        </div>
                        <div class="card-footer text-body-secondary">
                           <p>{{ charge.price.toFixed(2) }} €</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DartsBasarService from '@/services/DartsBasarService.js'

export default {
    name: 'ChargesView',
    data: () => {
        return {
            charges: null,
            loading: true,
        }
    },
    mounted: async function() {
        const token = this.$route.query.token
        this.charges = await this.getCharges(token)
        this.loading = false

        console.log(this.charges);
    },
    methods: {
        getCharges: async function(token) {
            return await DartsBasarService.getCharges(token)
        },
        changeRoute: function(to) {
            if (this.$router.currentRoute.fullPath != to) {
                this.$router.push(to)
            }
        },
        getTotalCharges: function() {
            if (this.charges == null) return null;
            let total = 0.0;
            
            for (const charge of this.charges) {
                total = total + charge.price;
            }

            return total;
        }
    },
}
</script>
