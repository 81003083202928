import APIFactory from '@/utils/APIFactory.js'

const baseUrl = 'https://backend.dartsbasar.de'
// const baseUrl = 'http://localhost:8082'

const getHome = async (token) => {
    if (token !== undefined && token != null) {
        return await APIFactory.get(`${baseUrl}?token=${token}`)
    }

    if (localStorage.token !== undefined && localStorage.token != null) {
        return await APIFactory.get(`${baseUrl}?token=${localStorage.token}`)
    }
}

const getCharges = async (token) => {
    if (token !== undefined && token != null) {
        return await APIFactory.get(`${baseUrl}/charges?token=${token}`)
    }

    if (localStorage.token !== undefined && localStorage.token != null) {
        return await APIFactory.get(`${baseUrl}/charges?token=${localStorage.token}`)
    }
}

const getOrders = async (token) => {
    if (token !== undefined && token != null) {
        return await APIFactory.get(`${baseUrl}/orders?token=${token}`)
    }

    if (localStorage.token !== undefined && localStorage.token != null) {
        return await APIFactory.get(`${baseUrl}/orders?token=${localStorage.token}`)
    }
}

const getShopSystems = async (token) => {
    if (token !== undefined && token != null) {
        return await APIFactory.get(`${baseUrl}/systems/?token=${token}`)
    }

    if (localStorage.token !== undefined && localStorage.token != null) {
        return await APIFactory.get(`${baseUrl}/systems?token=${localStorage.token}`)
    }
}

const getShopSystem = async (id, token) => {
    if (token !== undefined && token != null) {
        return await APIFactory.get(`${baseUrl}/system/${id}?token=${token}`)
    }

    if (localStorage.token !== undefined && localStorage.token != null) {
        return await APIFactory.get(`${baseUrl}/system/${id}?token=${localStorage.token}`)
    }
}

export default {
    getHome,
    getCharges,
    getOrders,
    getShopSystems,
    getShopSystem,
}